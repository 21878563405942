export enum TIPO_PERFIL_USUARIO {
  MASTER = 3,
  GESTOR_FROTA = 10,
  REPRESENTANTE = 18,
  AJUSTE_TRANSACOES = 14,

  ADMINISTRADOR_SISTEMA = 2,
  ADMINISTRADOR_REDE = 4,
  ADMINISTRADOR_CREDENCIADO = 5,
  ADMINISTRADOR_SUB_REDE = 6,

  FINANCEIRO = 7,
  FINANCEIRO_SUB_REDE = 8,

  CLIENTE = 9,
  FINANCEIRO_CLIENTE = 11,
  CONSULTAS_CLIENTE = 12,

  CREDENCIADO = 15,
  VENDAS_CREDENCIADO = 17,
  CADASTRO_CREDENCIADO = 13,
  FINANCEIRO_CREDENCIADO = 16,

  PARCEIRO = 19,
  GESTOR_MOTORISTA = 20,
  GESTOR_LIMITE_VEICULO = 21,
}
